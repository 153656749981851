

<template>
	<div class="tcontainer">
		<div style="margin: 10px;">
			<Calendar @hide="loadLazyData" placeholder="Select Range" v-model="dateRange" selectionMode="range"
				dateFormat="dd/mm/yy" />
		</div>

		<div class="clearfix ttitle">
			<div class="pull-right" style="margin: 5px;">
				<div>
					This Month Billing: &#8377; {{ sums?.thisMonthBilling }} (Reports: {{ sums?.thisMonthReports }})
				</div>
				<div>
					Last Month Billing: &#8377; {{ sums?.lastMonthBilling }} (Reports: {{ sums?.lastMonthReports }})
				</div>
			</div>
			<div class="title">Manage Xray Billing</div>
		</div>
		<DataTable stripedRows :value="tableRecords" :lazy="true" :resizableColumns="true" columnResizeMode="fit"
			:paginator="true" :rows="100" v-model:filters="filters" ref="dt" :totalRecords="totalRecords"
			:rowsPerPageOptions="[100, 200, 500, 1000]" :loading="loading" @page="onPage($event)" @sort="onSort($event)"
			@filter="onFilter($event)" filterDisplay="row" responsiveLayout="scroll">


			<Column field="id" header="Report Id" filterField="id" :sortable="true">
				<template #filter="{ filterModel, filterCallback }">
					<div>
						<input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control"
							placeholder="Report Id" />
					</div>
				</template>
				<template #body="{ data }">
					<div class="tdata">
						{{ data['id'] }}
					</div>
				</template>
			</Column>
			<!-- <Column field="center_id" header="Center Id" filterField="center_id" :sortable="true">
                <template #filter="{filterModel,filterCallback}">
                    <div>
                        <input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control" placeholder="Center Id"/>
                    </div>
                </template>
                <template #body="{data}">
                    <div class="tdata">
                        {{data['center_id']}}
                    </div>
                </template>
            </Column> -->
			<Column field="patient_name" header="Patient Name" filterField="patient_name" :sortable="true">
				<template #filter="{ filterModel, filterCallback }">
					<div>
						<input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control"
							placeholder="Patient Name" />
					</div>
				</template>
				<template #body="{ data }">
					<div class="tdata">
						{{ data['patient_name'] }}
					</div>
				</template>
			</Column>
			<Column field="test_name" header="Test Name" filterField="test_name" :sortable="true">
				<template #filter="{ filterModel, filterCallback }">
					<div>
						<input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control"
							placeholder="Test Name" />
					</div>
				</template>
				<template #body="{ data }">
					<div class="tdata">
						{{ data['test_name'] }}
					</div>
				</template>
			</Column>
			<Column field="test_category" header="Test Category" filterField="test_category" :sortable="true">
				<template #filter="{ filterModel, filterCallback }">
					<div>
						<input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control"
							placeholder="Test Category" />
					</div>
				</template>
				<template #body="{ data }">
					<div class="tdata">
						{{ data['test_category'] }}
					</div>
				</template>
			</Column>
			<Column field="center_name" header="Center Name" filterField="center_name" :sortable="true">
				<template #filter="{ filterModel, filterCallback }">
					<div>
						<input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control"
							placeholder="Center Name" />
					</div>
				</template>
				<template #body="{ data }">
					<div class="tdata">
						{{ data['center_name'] }}
					</div>
				</template>
			</Column>

			<!-- <Column field="patient_age" header="Patient Age" filterField="patient_age" :sortable="true">
                <template #filter="{filterModel,filterCallback}">
                    <div>
                        <input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control" placeholder="Patient Age"/>
                    </div>
                </template>
                <template #body="{data}">
                    <div class="tdata">
                        {{data['patient_age']}}
                    </div>
                </template>
            </Column>
            <Column field="patient_gender" header="Patient Gender" filterField="patient_gender" :sortable="true">
                <template #filter="{filterModel,filterCallback}">
                    <div>
                        <select v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control">
                            <option value="">Select Patient Gender</option>
                            
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                        </select>
                    </div>
                </template>
                <template #body="{data}">
                    <div class="tdata">
                        {{data['patient_gender']}}
                    </div>
                </template>
            </Column> -->
			<!-- <Column field="ref_doctor_name" header="Ref Doctor Name" filterField="ref_doctor_name" :sortable="true">
                <template #filter="{filterModel,filterCallback}">
                    <div>
                        <input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control" placeholder="Ref Doctor Name"/>
                    </div>
                </template>
                <template #body="{data}">
                    <div class="tdata">
                        {{data['ref_doctor_name']}}
                    </div>
                </template>
            </Column> -->
			<!-- <Column field="test_id" header="Test Id" filterField="test_id" :sortable="true">
                <template #filter="{filterModel,filterCallback}">
                    <div>
                        <input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control" placeholder="Test Id"/>
                    </div>
                </template>
                <template #body="{data}">
                    <div class="tdata">
                        {{data['test_id']}}
                    </div>
                </template>
            </Column>
            <Column field="test_date" header="Test Date" filterField="test_date" :sortable="true">
                <template #filter="{filterModel,filterCallback}">
                    <div>
                        <input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control" placeholder="Test Date"/>
                    </div>
                </template>
                <template #body="{data}">
                    <div class="tdata">
                        {{data['test_date']}}
                    </div>
                </template>
            </Column> -->
			<!-- <Column field="history" header="History" filterField="history" :sortable="true">
                <template #filter="{filterModel,filterCallback}">
                    <div>
                        <input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control" placeholder="History"/>
                    </div>
                </template>
                <template #body="{data}">
                    <div class="tdata">
                        {{data['history']}}
                    </div>
                </template>
            </Column> -->
			<!-- <Column field="reg_no" header="Reg No" filterField="reg_no" :sortable="true">
                <template #filter="{filterModel,filterCallback}">
                    <div>
                        <input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control" placeholder="Reg No"/>
                    </div>
                </template>
                <template #body="{data}">
                    <div class="tdata">
                        {{data['reg_no']}}
                    </div>
                </template>
            </Column> -->
			<!-- <Column field="mobile_no" header="Mobile No" filterField="mobile_no" :sortable="true">
                <template #filter="{filterModel,filterCallback}">
                    <div>
                        <input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control" placeholder="Mobile No"/>
                    </div>
                </template>
                <template #body="{data}">
                    <div class="tdata">
                        {{data['mobile_no']}}
                    </div>
                </template>
            </Column> -->
			<!-- <Column field="assign_doc_id" header="Assign Doc Id" filterField="assign_doc_id" :sortable="true">
                <template #filter="{filterModel,filterCallback}">
                    <div>
                        <input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control" placeholder="Assign Doc Id"/>
                    </div>
                </template>
                <template #body="{data}">
                    <div class="tdata">
                        {{data['assign_doc_id']}}
                    </div>
                </template>
            </Column>
            <Column field="assign_doc_ids" header="Assign Doc Ids" filterField="assign_doc_ids" :sortable="true">
                <template #filter="{filterModel,filterCallback}">
                    <div>
                        <input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control" placeholder="Assign Doc Ids"/>
                    </div>
                </template>
                <template #body="{data}">
                    <div class="tdata">
                        {{data['assign_doc_ids']}}
                    </div>
                </template>
            </Column>
            <Column field="images" header="Images" filterField="images" :sortable="true">
                <template #filter="{filterModel,filterCallback}">
                    <div>
                        <input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control" placeholder="Images"/>
                    </div>
                </template>
                <template #body="{data}">
                    <div class="tdata">
                        {{data['images']}}
                    </div>
                </template>
            </Column>
            <Column field="report" header="Report" filterField="report" :sortable="true">
                <template #filter="{filterModel,filterCallback}">
                    <div>
                        <input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control" placeholder="Report"/>
                    </div>
                </template>
                <template #body="{data}">
                    <div class="tdata">
                        {{data['report']}}
                    </div>
                </template>
            </Column> -->
			<Column field="status" header="Status" filterField="status" :sortable="true">
				<template #filter="{ filterModel, filterCallback }">
					<div>
						<select v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control">
							<option value="">Select Status</option>

							<option value="pending">Pending</option>
							<option value="reported">Reported</option>
							<option value="printed">Printed</option>
							<option value="review">Review</option>
						</select>
					</div>
				</template>
				<template #body="{ data }">
					<div class="tdata">
						{{ data['status'] }}
					</div>
				</template>
			</Column>
			<!-- <Column field="urgent" header="Urgent" filterField="urgent" :sortable="true">
                <template #filter="{filterModel,filterCallback}">
                    <div>
                        <select v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control">
                            <option value="">Select Urgent</option>
                            
                            <option value="y">Y</option>
                            <option value="n">N</option>
                        </select>
                    </div>
                </template>
                <template #body="{data}">
                    <div class="tdata">
                        {{data['urgent']}}
                    </div>
                </template>
            </Column>
            <Column field="review_notes" header="Review Notes" filterField="review_notes" :sortable="true">
                <template #filter="{filterModel,filterCallback}">
                    <div>
                        <input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control" placeholder="Review Notes"/>
                    </div>
                </template>
                <template #body="{data}">
                    <div class="tdata">
                        {{data['review_notes']}}
                    </div>
                </template>
            </Column>
            <Column field="review_done" header="Review Done" filterField="review_done" :sortable="true">
                <template #filter="{filterModel,filterCallback}">
                    <div>
                        <select v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control">
                            <option value="">Select Review Done</option>
                            
                            <option value="y">Y</option>
                            <option value="n">N</option>
                        </select>
                    </div>
                </template>
                <template #body="{data}">
                    <div class="tdata">
                        {{data['review_done']}}
                    </div>
                </template>
            </Column>
            <Column field="notes" header="Notes" filterField="notes" :sortable="true">
                <template #filter="{filterModel,filterCallback}">
                    <div>
                        <input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control" placeholder="Notes"/>
                    </div>
                </template>
                <template #body="{data}">
                    <div class="tdata">
                        {{data['notes']}}
                    </div>
                </template>
            </Column> -->
			<Column field="agent_price" header="Price" filterField="agent_price" :sortable="true">
				<template #filter="{ filterModel, filterCallback }">
					<div>
						<input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control"
							placeholder="Price" />
					</div>
				</template>
				<template #body="{ data }">
					<div class="tdata">
						{{ data['agent_price'] }}
					</div>
				</template>
			</Column>
			<Column field="created_at" header="Created At" filterField="created_at" :sortable="true">
				<template #filter="{ filterModel, filterCallback }">
					<div>
						<input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control"
							placeholder="Created At" />
					</div>
				</template>
				<template #body="{ data }">
					<div class="tdata">
						{{ data['created_at'] }}
					</div>
				</template>
			</Column>
			<!-- <Column field="reported_at" header="Reported At" filterField="reported_at" :sortable="true">
                <template #filter="{filterModel,filterCallback}">
                    <div>
                        <input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control" placeholder="Reported At"/>
                    </div>
                </template>
                <template #body="{data}">
                    <div class="tdata">
                        {{data['reported_at']}}
                    </div>
                </template>
            </Column> -->
			<!-- <Column field="accepted_at" header="Accepted At" filterField="accepted_at" :sortable="true">
                <template #filter="{filterModel,filterCallback}">
                    <div>
                        <input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control" placeholder="Accepted At"/>
                    </div>
                </template>
                <template #body="{data}">
                    <div class="tdata">
                        {{data['accepted_at']}}
                    </div>
                </template>
            </Column> -->
			<Column header="Options">
				<template #body="{ data }">
					<div class="tdata options">
						<button v-if="data.review_notes" @click="showReviewNotesBox(data)"
							class="btn btn-sm btn-outline-secondary">
							<i class="fa fa-eye"></i>
						</button>
					</div>
				</template>
			</Column>
		</DataTable>


		<div class="modal fade" ref="reviewNotesModal" tabindex="-1">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header bg-info text-white">
						<h5 class="modal-title">Review Notes</h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div style="padding: 20px;">
						{{ editRow?.review_notes }}

						<div v-if="editRow?.reviewAudioUrl" style="padding-top: 10px;">
							<audio :src="editRow.reviewAudioUrl" controls style="display: block; width: 100%;"></audio>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Calendar from 'primevue/calendar';
import { Modal } from 'bootstrap';

export default {
	components: {
		Calendar
	},
	data() {
		return {
			loading: false,
			totalRecords: 0,
			tableRecords: null,
			editId: 0,
			editRow: {},
			saving: false,
			lazyParams: {},
			dateRange: "",
			sums: {},
			reviewNotesModal: null,
			filters: {
				'id': { value: '', matchMode: 'like' },
				'center_id': { value: '', matchMode: 'like' },
				'center_name': { value: '', matchMode: 'like' },
				'patient_name': { value: '', matchMode: 'like' },
				'patient_age': { value: '', matchMode: 'like' },
				'patient_gender': { value: '', matchMode: 'like' },
				'ref_doctor_name': { value: '', matchMode: 'like' },
				'test_id': { value: '', matchMode: 'like' },
				'test_name': { value: '', matchMode: 'like' },
				'test_category': { value: '', matchMode: 'like' },
				'test_date': { value: '', matchMode: 'like' },
				'history': { value: '', matchMode: 'like' },
				'reg_no': { value: '', matchMode: 'like' },
				'mobile_no': { value: '', matchMode: 'like' },
				'assign_doc_id': { value: '', matchMode: 'like' },
				'assign_doc_ids': { value: '', matchMode: 'like' },
				'images': { value: '', matchMode: 'like' },
				'report': { value: '', matchMode: 'like' },
				'status': { value: '', matchMode: 'like' },
				'urgent': { value: '', matchMode: 'like' },
				'review_notes': { value: '', matchMode: 'like' },
				'review_done': { value: '', matchMode: 'like' },
				'notes': { value: '', matchMode: 'like' },
				'price': { value: '', matchMode: 'like' },
				'agent_price': { value: '', matchMode: 'like' },
				'created_at': { value: '', matchMode: 'like' },
				'reported_at': { value: '', matchMode: 'like' },
				'accepted_at': { value: '', matchMode: 'like' },
			},
			fdata: {
				id: '',
				center_id: '',
				patient_name: '',
				patient_age: '',
				patient_gender: '',
				ref_doctor_name: '',
				test_id: '',
				test_date: '',
				history: '',
				reg_no: '',
				mobile_no: '',
				assign_doc_id: '0',
				assign_doc_ids: '',
				images: '',
				report: '',
				status: 'pending',
				urgent: 'n',
				review_notes: '',
				review_done: 'n',
				notes: '',
				price: '0',
				created_at: '',
				reported_at: '',
				accepted_at: '',
			},
			fvalidator: {
				center_id: 'required',
				patient_name: 'required|max:30',
				patient_age: 'required|max:15',
				patient_gender: 'required|in:male,female',
				ref_doctor_name: 'required|max:50',
				test_id: 'required',
				test_date: 'required',
				history: 'required',
				reg_no: 'required|max:40',
				mobile_no: 'required|max:15',
				assign_doc_id: '',
				assign_doc_ids: 'required|max:255',
				images: '',
				report: '',
				status: 'required|in:pending,reported,printed,review',
				urgent: 'required|in:y,n',
				review_notes: '',
				review_done: 'required|in:y,n',
				notes: '',
				price: 'required',
				created_at: 'required',
				reported_at: '',
				accepted_at: '',
			}
		}
	},
	mounted() {

		this.reviewNotesModal = new Modal(this.$refs.reviewNotesModal, { backdrop: 'static', keyboard: false });

		this.lazyParams = {
			first: 0,
			rows: this.$refs.dt.rows,
			sortField: null,
			sortOrder: null,
			filters: this.filters,
		};
		this.loadLazyData();

		this.get("agent/load-recent-xray-billing").then(res => {
			this.sums = res
		});
	},
	methods: {
		showReviewNotesBox(data) {
			this.editRow = data;
			this.reviewNotesModal.show();
		},
		loadLazyData() {
			this.loading = true;
			let params = JSON.parse(JSON.stringify(this.lazyParams));
			for (let f in params.filters) {
				if (!params.filters[f].value.length) delete params.filters[f];
			}

			if (!params.sortField) {
				params.sortField = "id";
				params.sortOrder = -1;
			}

			params.sums = [['agent_price', 'totalPrice']]
			params.joins = [
				{
					"fTable": "tests",
					"fKey": "test_id",
					"fField": [["name", "test_name"], ["category", "test_category"]]
				},
				{
					"fTable": "centers",
					"fKey": "center_id",
					"fField": [["name", "center_name"]]
				},
			]

			// new Date().toISOString()
			if (this.dateRange[0] && this.dateRange[1]) {
				// params.filters['created_at'] = { value: [this.dateRange[0].toISOString(), this.dateRange[1].toISOString()], matchMode: "between" }
				params.filters['created_at'] = { value: [this.format(this.dateRange[0], "YYYY-MM-DD") + ' 00:00:00', this.format(this.dateRange[1], "YYYY-MM-DD") + " 23:59:59"], matchMode: "between" };
			}
			this.post("agent/load-xray-reports", params).then(res => {
				this.loading = false;
				if (res.success) {
					this.totalRecords = res.totalRecords;
					this.tableRecords = res.rows;
					
					for(let row of this.tableRecords){
						if(row.review_audio_file){
							row.reviewAudioUrl = this.apiOrigin + "/" + 'images/radiology/' + row.review_audio_file;
						}
					}

				}
			});
		},
		onPage(event) {
			this.lazyParams = event;
			this.loadLazyData();
		},
		onSort(event) {
			this.lazyParams = event;
			this.loadLazyData();
		},
		onFilter() {
			this.loading = true;
			this.lazyParams.filters = this.filters;
			this.loadLazyData();
		}
	}
}
</script>
<style scoped></style>